/* @jsx jsx */
import {
  jsx,
  Heading,
  Box,
  Divider,
  Flex,
  Grid,
  Text,
  Badge,
  useThemeUI,
} from "theme-ui";
import * as React from "react";
import { PageProps, graphql, Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { Helmet } from "react-helmet";
import { FaChevronLeft } from "react-icons/fa";
import { MdSubdirectoryArrowLeft } from "react-icons/md";
import Dayjs from "dayjs";
import ConfLogo from "../../components/ConfLogo";
import HubspotForm from "../../components/HubspotForm";
import Layout from "../../components/Layout";
import RichText from "../../components/RichText";
import Presenters from "../../components/Presenters";

const scheduleTimeFormat = "MMMM D, YYYY";
const eventTimeFormat = "H:mm";

type DataProps = {
  contentfulEvent: {
    title: string;
    description: {
      raw: string;
    };
    metaDescription: string;
    workshopLevel: string;
    datetime: string;
    duration: number;
    presenters: any;
    eventType: string;
    prerequisites: {
      raw: string;
    };
    socialImage: {
      file: {
        url: string;
      };
    };
  };
  allRegistrant: {
    totalCount: number;
  };
};

const contentCardStyles = (eventType: string) => ({
  position: `relative`,
  color: `${eventType}.primary`,
  flexDirection: `column`,
  width: `100%`,
  p: [`4`, `5`, `6`],
  mt: [`4`, `0`],
  borderRadius: [`6`, `9`],
  gridGap: `3`,
  "&::before": {
    content: `""`,
    position: `absolute`,
    zIndex: -3,
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    borderRadius: [`6`, `9`],
    opacity: 1,
    backgroundColor: `${eventType}.background`,
  },
});

const dateStyles = (eventType: string) => ({
  color: `${eventType}.primary`,
  fontSize: [`2`, `3`],
  variant: `text.capitalized`,
});

const containerStyles = {
  position: `relative`,
  pt: `4`,
  pb: [`4`, `6`],
  alignItems: `center`,
  gridGap: [`3`, `4`, `5`],
  gridTemplateRows: `repeat(4, auto)`,
};

const formStyles = {
  px: [4, 5, 6, 0],
};

const headingStyles = {
  fontSize: [`5`, `6`, `7`],
};

const contentGridStyles = {
  gridTemplateColumns: [`1fr`, `1fr`, `1fr`],
  gridGap: `4`,
  my: `3`,
};

const presenterFlexStyles = {
  flexDirection: [`column`, null, `row`],
  justifyContent: `space-between`,
};

const prereqStyles = {
  gridTemplateColumns: `repeat(2, max-content)`,
  gridGap: `3`,
  alignItems: `center`,
};

const badgeStyles = {
  color: `magenta`,
  "&:empty": {
    display: `none`,
  },
};

const minorHeaderStyles = {
  display: `flex`,
  justifyContent: `space-between`,
  px: [`3`, null],
};

const HEADER_ITEM_WIDTH = [100, 140, 175];
const backLinkStyles = {
  width: HEADER_ITEM_WIDTH,
  fontSize: `2`,
  display: `flex`,
  alignItems: `center`,
  variant: `text.link`,
};

const emptyBoxStyles = {
  width: HEADER_ITEM_WIDTH, // making this the same size
};

const backgroundAvatarStyles = (eventType: string) => ({
  position: `absolute`,
  zIndex: -1,
  top: [450, 400, 350],
  right: [20, 40, 30, -60],
  isolation: `isolate`,
  backgroundColor: `${eventType}.background`,
  borderRadius: 9999,
  opacity: [0.15, 0.2, 0.25],
});

const backgroundAvatarImgStyles = {
  mixBlendMode: `multiply`,
  filter: `grayscale(1)`,
  borderRadius: 9999,
  // this is using important to overwrite the gatsby-plugin-image wrapper
  // that adds width/height to the container.
  width: [`240px !important`, null, `320px !important`, `360px !important`],
  height: [`240px !important`, null, `320px !important`, `360px !important`],
};

const formNoteStyles = {
  fontSize: [`2`, `3`],
  textAlign: `center`,
};
const homepageLinkStyles = (eventType: string) => ({
  variant: `text.link`,
  color: `${eventType}.primary`,
});

const EventPage = ({ data }: PageProps<DataProps>) => {
  const {
    contentfulEvent: {
      title,
      description,
      metaDescription,
      workshopLevel,
      eventType,
      datetime,
      youtubeVideoLink,
      duration,
      presenters,
      prerequisites,
      socialImage,
    },
    allRegistrant: { totalCount },
  } = data;
  const { theme } = useThemeUI();
  const colors = theme?.colors[eventType];

  // Format start and endtime of event
  const eventTime = Dayjs(datetime);
  const startTime = `${eventTime.format(eventTimeFormat)}`;
  const endTime = `${eventTime.add(+duration, "m").format(eventTimeFormat)}`;

  const ogImageUrl = `https:${socialImage?.file?.url}`;
  const ogTitle = `${data.contentfulEvent.title} | GatsbyConf · Mar 2-3, 2021`;

  return (
    <Layout>
      <Helmet>
        <title>{ogTitle}</title>
        <meta
          name="description"
          content={
            metaDescription ??
            "Come learn how Gatsby is pushing forward the future web at GatsbyConf."
          }
        />
        <meta name="twitter:title" content={ogTitle} />
        <meta property="og:title" content={ogTitle} />
        <meta name="image" content={ogImageUrl} />
        <meta itemProp="image" content={ogImageUrl} />
        <meta name="twitter:image" content={ogImageUrl} />
        <meta property="og:image" content={ogImageUrl} />
        <html lang="en" />
      </Helmet>
      <Grid sx={containerStyles} flexDirection="column">
        <Box sx={minorHeaderStyles}>
          <Link to="/" sx={backLinkStyles}>
            <FaChevronLeft />{" "}
            <span sx={{ display: [`none`, `none`, `flex`] }}>
              Go back to all events
            </span>
            <span sx={{ display: [`flex`, `flex`, `none`] }}>Go Back</span>
          </Link>
          <Box>
            <ConfLogo />
          </Box>
          <Box sx={emptyBoxStyles} />
        </Box>
        <Box sx={backgroundAvatarStyles(eventType)}>
          <GatsbyImage
            sx={backgroundAvatarImgStyles}
            image={
              presenters &&
              presenters[0]?.headshot?.localFile?.childImageSharp?.backgroundImg
            }
            alt={presenters && presenters[0]?.name}
          />
        </Box>
        <Grid sx={contentCardStyles(eventType)}>
          <Text sx={dateStyles(eventType)}>
            {Dayjs(datetime).format(scheduleTimeFormat)}
            <br />
            {`${startTime} – ${endTime}`}
          </Text>
          <Heading as="h1" sx={headingStyles}>
            {title}
          </Heading>
          <Grid sx={contentGridStyles}>
            <Box>
              <Text
                sx={{
                  color: `${eventType}.primary`,
                  fontSize: [`3`, `4`],
                }}
              >
                {metaDescription}
              </Text>
            </Box>
            <Flex sx={presenterFlexStyles}>
              <Presenters presenters={presenters} textColor={colors?.primary} />
            </Flex>
          </Grid>
          <React.Fragment>
            {prerequisites && (
              <Grid sx={prereqStyles}>
                <Badge sx={badgeStyles}>{workshopLevel}</Badge>
                <Box>
                  <Link
                    to="#prerequisites"
                    sx={{ color: `magenta`, textDecoration: `none` }}
                  >
                    Prerequisites{" "}
                    <MdSubdirectoryArrowLeft
                      sx={{ transform: `rotate(270deg) translateX(-5px)` }}
                    />
                  </Link>
                </Box>
              </Grid>
            )}
            <Divider sx={{ color: `${eventType}.primary` }} />
            <Box>
              {youtubeVideoLink && (
                <iframe
                  width={560}
                  height={315}
                  src={`https://www.youtube.com/embed/${youtubeVideoLink}?playlist=${youtubeVideoLink}&rel=0&loop=1&ytp-pause-overlay=0&modestbranding=1`}
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  frameBorder="0"
                  webkitallowfullscreen="true"
                  mozallowfullscreen="true"
                  allowFullScreen
                />
              )}
            </Box>
            <Box>
              {description && (
                <RichText
                  richTextNode={description}
                  sx={{ color: `${eventType}.primary` }}
                  align={`left`}
                />
              )}
            </Box>
            {prerequisites && (
              <React.Fragment>
                <Heading
                  as="h2"
                  sx={{ fontSize: `3`, mt: [`4`, `5`] }}
                  variant="text.capitalized"
                >
                  <span id="prerequisites">Prerequisites</span>
                </Heading>
                <Box>
                  <RichText
                    sx={{ color: `magenta` }}
                    richTextNode={prerequisites}
                  />
                </Box>
              </React.Fragment>
            )}
          </React.Fragment>
        </Grid>
        <Box sx={formStyles}>
          <HubspotForm
            title={`Join us by claiming your ticket!`}
            decorativeText={`${totalCount} people have already signed up!`}
          />
        </Box>
        <Box sx={formNoteStyles}>
          <Text>
            Find out more about <b>GatsbyConf</b> on
            <span>{` `}</span>
            <Link to="/" sx={homepageLinkStyles(eventType)}>
              our homepage
            </Link>
            .
          </Text>
        </Box>
      </Grid>
    </Layout>
  );
};

export default EventPage;

export const query = graphql`
  query($id: String!) {
    contentfulEvent(id: { eq: $id }) {
      ...Event
    }
    allRegistrant {
      totalCount
    }
  }
`;
